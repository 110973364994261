<template>
  <v-container fluid fill-height class="background">
    <v-layout align-center justify-center>
      <v-col xs12 sm8 md4 v-if="!forgotPassword">
        <v-card flat color="transparent">
          <v-img
          :src="logo"
          class="mb-5"
          aspect-ratio="1"
          contain
          max-height="85"
        >
        </v-img>

          <v-card-text>
            <div class="headline text-xs-center white--text">{{ $t('login.forgotPasswordLabel') }}</div>
            <div class="subheading text-xs-center white--text">{{$t('login.forgotPassword')}}</div>
              <v-form @keyup.enter="sendResetRequest" ref="form" lazy-validation>
                <v-text-field
                  name="username"
                  :label="$t('activate.usernameLabel')"
                  v-model="username"
                  type="text"
                  v-validate="'required|email'"
                  :error-messages="errors.collect('username')"
                  data-vv-name="username"
                  required>
                </v-text-field>
              </v-form>
          </v-card-text>
          <v-card-actions>
            <v-layout row wrap>
              <v-flex xs12>
                <v-btn :primary="$t('login.buttonColor')"
                      @click="sendResetRequest()"
                      block
                      :loading="loading"
                      >{{ $t('button.resendReset') }}</v-btn>
              </v-flex>
              <v-flex xs12 class="mt-3">
                <router-link :to="'/login'" :class="$t('activate.linkClass')">
                  <h3 class="text-xs-center">{{ $t('activate.loginLink') }}</h3>
                </router-link>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>`
      </v-col>
        <v-flex xs12 sm8 md4 v-if="forgotPassword == true" transition="slide-x-reverse-transition">
        <v-img
          :src="logo"
          class="mb-5"
          aspect-ratio="1"
          contain
          max-height="85"
        >
        </v-img>
        <h2>{{ $t('forgotPassword.forgotPassword') }}</h2>
        <v-text-field
            id="code"
            prepend-icon="lock"
            v-model="code"
            name="code"
            :label="$t('forgotPassword.codeLabel')"
            v-validate="'required'"
            :error-messages="errors.collect('code')"
            data-vv-name="code"
            required>
        </v-text-field>
        <v-text-field
            id="password"
            prepend-icon="lock"
            v-model="newPassword"
            name="password"
            :label="$t('forgotPassword.newPasswordLabel')"
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            v-validate="'required'"
            :error-messages="errors.collect('password')"
            data-vv-name="password"
            required>
        </v-text-field>
         <v-btn id="newPasswordBtn" :color="$t('forgotPassword.buttonColor')"
                @click="resetPassword()"
                block
                :loading="loading"
                >{{ $t('button.resetPassword') }}</v-btn>
        </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { Auth } from 'aws-amplify'

Vue.use(VeeValidate)
export default {
  data () {
    return {
      username: null,
      loading: false,
      logo: process.env.S3 + window.location.host + '/loginLogo.png',
      showPassword: false,
      newPassword: '',
      newPasswordUser: null,
      forgotPassword: false,
      code: ''
    }
  },
  methods: {
    resetPassword () {
      const scope = this
      Auth.forgotPasswordSubmit(this.username, this.code, this.newPassword)
        .then(function (loggedUser) {
          scope.$snotify.info('Password has been reset. You may now login.', {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          })
          scope.$router.push('/login')
        })
        .catch(function (err) {
          scope.$snotify.error(err.message, {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          })
          scope.loading = false
        })
    },
    sendResetRequest: function () {
      const scope = this

      this.$validator.validateAll().then(function (res) {
        if (res) {
          scope.loading = true
          Auth.forgotPassword(scope.username)
            .then(function (data) {
              scope.forgotPassword = true
              scope.loading = false
            })
            .catch(function (err) {
              scope.$snotify.error(err.message, {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              })
              scope.loading = false
            })
        }
      })
    }
  }
}
</script>
<style scoped>
    .background {
    color: #fff!important;
    /* background: linear-gradient(var(--v-accent-lighten1), var(--v-primary-base)); */
    background-image: url('/static/img/images/house.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
